import {BaseRequest} from "@/request/BaseRequest";

class ConsultCustomer extends BaseRequest {

    public requestPath: string = '/webDomain/consultCustomer';

    /**
     * 处理消息
     * @param data
     */
    deal(data: {id: string, answerContent: string}): any {
        return this.post(`${this.requestPath}/deal`, data);
    }

}

const c = new ConsultCustomer();
export {c as ConsultCustomer};