





















import {Vue, Component} from "vue-property-decorator";
import {ConsultCustomer} from "@/request/webDomain/ConsultCustomer";

@Component({
    name: 'webDomain_consultCustomer'
})
export default class webDomain_consultCustomer extends Vue {
    modal: any = ConsultCustomer;
    dealModal: any = {
        show: false,
        loading: true,
        id: '',
        content: ''
    };
    columns: Array<any> = [
        {
            title: '用户名称',
            key: 'name',
            minWidth: 160
        },
        {
            title: '电话',
            key: 'phone',
            minWidth: 140
        },
        {
            title: '微信',
            key: 'wx',
            minWidth: 120
        },
        {
            title: '商家类型',
            key: 'businessType',
            minWidth: 120
        },
        {
            title: '回访内容',
            key: 'answerContent',
            minWidth: 120
        },
        {
            title: '回访时间',
            key: 'answerTime',
            minWidth: 180
        },
        {
            title: '创建时间',
            key: 'createTime',
            minWidth: 180
        }
    ];
    deal(row: any) {
        this.dealModal.id = row.id;
        this.dealModal.content = '';
        this.dealModal.show = true;
    }
    doDeal() {
        ConsultCustomer.deal({
            id: this.dealModal.id,
            answerContent: this.dealModal.content
        }).then((body: any) => {
            console.log(body);
            if (body.code === 0) {
                this.dealModal.show = false;
                this.$Message.info('处理成功');
            }
        })
    }
}
